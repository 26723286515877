
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import Header from "@/components/header/index.vue";
import {useStore} from 'vuex'
import { RootState } from "@/store";

export default defineComponent({
  name: "Home",
  components: { Header },
  setup() {
    const bkcolor = ref("white");
    const store = useStore<RootState>();
    onMounted(()=>{
      store.commit("ui/setJob",true)
      
    })
    onBeforeUnmount(()=>{
      store.commit("ui/setJob",false)
    })
    return { bkcolor };
  },
});
