
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import Header from "@/components/header/index.vue";
import { useStore } from "vuex";
import { RootState } from "@/store";
import { useRoute, onBeforeRouteUpdate } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header },
  setup() {
    const bkcolor = ref("white");
    const store = useStore<RootState>();
    const page = ref(null);
    const route = useRoute();
    onBeforeRouteUpdate((to) => {
      if (to.query.gh_jid) {
        show.value = false;
      } else {
        show.value = true;
      }
    });
    onMounted(() => {
      store.commit("ui/setJob", true);
      nextTick(() => {
        let myEvent = new Event("resize");
        window.dispatchEvent(myEvent);
      });
    });
    onBeforeUnmount(() => {
      store.commit("ui/setJob", false);
    });
    const toJob = () => {
      if (page.value) {
        const dom: any = page.value;
        const y = dom.getBoundingClientRect().bottom;
        window.scrollTo(0, y - 80);
      }
    };
    const show = ref(true);
    if (route.query.gh_jid) {
      show.value = false;
    }
    return { bkcolor, page, toJob, show };
  },
});
